import React, { useState } from 'react';
import { showSuccessToast, showFailedToast } from "../../components/ToastNotifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    // Validation
    if (!name.trim()) {
      showFailedToast('Please enter your name', "top-center");
      return;
    }
    if (!email.trim()) {
      showFailedToast('Please enter your email', "top-center");
      return;
    }
    const formData = new FormData();
    formData.append('entry.1566939274', email);
    formData.append('entry.1981755434', name);
    formData.append('entry.897605534', 'All Courses');
    // POST request
    fetch('https://docs.google.com/forms/d/1PyD57ljhjpsgHqBD7RSe3pAThGrPpyUWXFxvndgbS74/formResponse', {
      method: 'POST',
      body: formData,
    })
    .then(response => {
      // Clear form fields
      if (response.ok) {
        // Handle successful response
        console.log('Form submitted successfully');
        showSuccessToast('Thanks, we\'ll get back to you shortly.',"top-center");
        setName('');
        setEmail('');
        // You can show a success message here
      } else {
        // Handle error response
        console.error('Error submitting form');
        showSuccessToast('Thanks, we\'ll get back to you shortly.',"top-center");
        setName('');
        setEmail('');
        // You can show an error message here
      }
    })
    .catch(error => {
      console.error('Error submitting form:', error);
      showSuccessToast('Thanks, we\'ll get back to you shortly.',"top-center");
      setName('');
      setEmail('');
      // You can show an error message here
    });
  };

  return (
    <section className="pb-115" id="contact">
      <div className="container">
        <div className="contact-form">
          <div className="content">
            <div>
              <h2>Keep up to date!</h2>
              <p>Be the first to know about our newest courses and exclusive promotions.</p>
            </div>
            <div>
            <form style={{ margin: '35px 0 10px 0' }} onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn primary"
                      type="submit"
                      aria-label="contact us"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              <span>
                By continuing, you agree to our
                <a href="https://skillbakery.com/privacy.html"> Privacy Policy</a>
              </span>
            </div>
          </div>
          <div className='contactdetails'>
            <i className="far fa-envelope" style={{fontSize:50,}}>
              <FontAwesomeIcon icon={faEnvelope} />
            </i><br></br>
            <span style={{fontWeight:600,display:'block'}}>WRITE TO US</span> contact@skillbakery.com
          </div>
        </div>
      </div>
    </section>
  );
}